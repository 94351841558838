
import './App.css';

import { useEffect, useState } from 'react';

function App() {

  const MAX_CHOCOLATE = 7;
  const CHOCOLATE_BOMB = 6;
  const CHOCOLATE_HEART = 7;

  const [ gold, setGold ] = useState(parseInt(localStorage.getItem('gold') || 0));
  const [ diamond, setDiamond ] = useState(parseInt(localStorage.getItem('diamond') || 0));
  const [ chicken, setChicken ] = useState(parseInt(localStorage.getItem('chicken') || 0));
  const [ heart, setHeart ] = useState(parseInt(localStorage.getItem('heart') || 3));

  const [ hasLeftChocolate, setHasLeftChocolate ] = useState(true);
  const [ hasRightChocolate, setHasRightChocolate ] = useState(true);
  const [ menuShown, setMenuShown ] = useState(false);
  const [ leftHandChocolate, setLeftHandChocolate ] = useState(1);
  const [ rightHandChocolate, setRightHandChocolate ] = useState(2);
  const [ numberOfChocolateTypes, setNumberOfChocolateTypes ] = useState(parseInt(localStorage.getItem('number_of_chocolate_types') || 2));
  const [ direction, setDirection ] = useState('forward');
  const [ direction2, setDirection2 ] = useState('forward');
  

  useEffect(() => {
    localStorage.setItem('gold', gold);
    localStorage.setItem('diamond', diamond);
    localStorage.setItem('chicken', chicken);
    localStorage.setItem('number_of_chocolate_types', numberOfChocolateTypes);
    localStorage.setItem('heart', heart);
  }, [gold, diamond, chicken, numberOfChocolateTypes, heart]);

  const eatChocolate = (side) => {
    let chocolate = (side === 'left' ? leftHandChocolate : rightHandChocolate);
    if (chocolate === CHOCOLATE_BOMB) {
      let chance = Math.random();
      if (chance < 0.5) {
        if (heart > 0) {
          setHeart(heart - 1);
        } else {
          if (gold > 10) {
            setGold(gold - 10);
          }
        }
      } else {
        setDiamond(diamond + 1);
      }
    } else if (chocolate === CHOCOLATE_HEART) {
      setHeart(heart + 1);
    } else {
      setGold(gold + 1);
    }

    if (side === 'left') {
      setLeftHandChocolate(1+Math.floor(Math.random()*numberOfChocolateTypes));
      setHasLeftChocolate(true);
    } else {
      setRightHandChocolate(1+Math.floor(Math.random()*numberOfChocolateTypes));
      setHasRightChocolate(true);
    }
  };

  const convertGoldToDiamond = () => {
    if (direction === 'forward') {
      if (gold >= 15) {
        setDiamond(diamond+5);
        setGold(gold-15);
      }
    } else {
      if (diamond >= 5) {
        setGold(gold+15);
        setDiamond(diamond-5);
      }
    }
  };

  const convertChickenToHeart = () => {
    if (direction === 'forward') {
      if (chicken >= 2) {
        setChicken(chicken-2);
        setHeart(heart+1);
      }
    } else {
      if (diamond >= 1) {
        setChicken(chicken+2);
        setHeart(heart-1);
      }
    }
  };

  const addOneMoreTypeOfChocolate = () => {
    if (numberOfChocolateTypes < MAX_CHOCOLATE) {
      setDiamond(diamond-16); 
      setNumberOfChocolateTypes(numberOfChocolateTypes + 1);
    }
  }

  const addChicken = () => {
    setDiamond(diamond-50); 
    setChicken(chicken+1);
  }

  const isButtonDisable = () => {
    if (direction === 'forward')
      return gold < 15 ? true : false;
    else
      return diamond < 5 ? true : false;
  }

  const isButtonDisable2 = () => {
    if (direction === 'forward')
      return chicken < 2 ? true : false;
    else
      return heart < 1 ? true : false;
  }

  const changeDirection = () => {
    setDirection(direction === 'forward' ? 'backward' : 'forward');
  }

  const changeDirection2 = () => {
    setDirection2(direction2 === 'forward' ? 'backward' : 'forward');
  }

  const drawHearts = () => {
    let str = '';
    for (var i = 0; i < heart; i++) {
      str += '♥';
    }
    return str;
  }

  return (
    <div className="App">

      <div className="topbar">
        Diamond:
        <span id="diamond"> {diamond} </span>
         | Gold: 
        <span id="gold"> {gold} </span> 
         | Chicken: 
        <span id="chicken"> {chicken} </span> 
        <span id="menubutton" onClick={() => {setMenuShown(!menuShown)}}>⁞</span>
        <div id="menu" hidden={!menuShown}>
          <ul>
            <li>
              <span className="goldValue">15</span> golds 
              { direction === 'forward' ? ' => ' : ' <= ' } 
              <span className="diamondValue">5</span> diamonds
              <button className="giveButton" disabled={isButtonDisable()} onClick={convertGoldToDiamond}>Give</button>
              <br/>
              <button onClick={changeDirection}>Change Direction</button>
              <p></p>
            </li>
            <li>
              <span className="diamondValue">16</span> diamonds = <span className="chocolateValue">1</span> new chocolate
              <button className="giveButton" disabled={diamond < 16 || numberOfChocolateTypes === MAX_CHOCOLATE ? true : false} onClick={addOneMoreTypeOfChocolate}>Buy</button>
              <p></p>
            </li>

            <li>
              <span className="diamondValue">50</span> diamonds = <span className="chickenValue">1</span> new chicken
              <button className="giveButton" disabled={diamond < 50 ? true : false} onClick={addChicken}>Buy</button>
              <p></p>
            </li>

            <li>
              <span className="goldValue">15</span> golds 
              { direction === 'forward' ? ' => ' : ' <= ' } 
              <span className="diamondValue">5</span> diamonds
              <button className="giveButton" disabled={isButtonDisable()} onClick={convertGoldToDiamond}>Give</button>
              <br/>
              <button onClick={changeDirection}>Change Direction</button>
              <p></p>
            </li>

            <li>
              <span className="chickenValue">2</span> chickens
              { direction2 === 'forward' ? ' => ' : ' <= ' } 
              <span className="heartValue">1</span> heart
              <button className="giveButton" disabled={isButtonDisable2()} onClick={convertChickenToHeart}>Change</button>
              <br/>
              <button onClick={changeDirection2}>Change Direction</button>
              <p></p>
            </li>


          </ul>
        </div>
      </div>
      
      <div className="playarea">
        <div id="lefthand" className="hand">
          <img alt="Left Hand" src={"chocolate" + leftHandChocolate + ".png"} className="chocolate" hidden={!hasLeftChocolate} onClick={() => eatChocolate('left')} />
        </div>
        <div id="righthand" className="hand">
          <img alt="Right Hand" src={"chocolate" + rightHandChocolate + ".png"} className="chocolate" hidden={!hasRightChocolate} onClick={() => eatChocolate('right')} />
        </div>
      </div>
      
      <div className="bottombar">{ drawHearts() }</div>
    </div>
  );
}

export default App;
